import layout from '@/layouts/page/page.vue'
import roomView from '@/components/room_view/room_view.vue'
import roomViewBoldTheme from '@/components/room_view_bold_theme/room_view_bold_theme.vue'
import roomViewExpressTheme from '@/components/room_view_express_theme/room_view_express_theme.vue'
import roomViewOverlayTheme from '@/components/room_view_overlay_theme/room_view_overlay_theme.vue'
import divider from '@/components/general/divider.vue'
import {Loader} from 'vue-feather-icon'

export default {
	mounted() {
		if (this.hotel) {
      document.getElementsByTagName('title')[0].innerHTML = 'Rooms | ' + this.hotel_name;
    } else {
      this.$store.dispatch('setPropDetails');
    }
	},
	components: {
		layout,
		roomView,
    roomViewBoldTheme,
    roomViewExpressTheme,
    roomViewOverlayTheme,
		divider,
    Loader: Loader.default
	},
	computed: {
	  rooms_with_covers() {

      let rooms = !!this.rooms && this.rooms.filter(room => !!room.cover_photo ? (room.all_images = [{url:room.cover_photo}, ...room.images]): room.all_images = [...room.images]);

      if (this.theme === 'express-theme') {
        console.log("ROOMS",rooms);
        return rooms.sort((a, b) => a.upsell_sequence_number - b.upsell_sequence_number)
      } else {
        console.log("ROOMS",rooms);
        return rooms
      }
    },
    configRoomTypes() {
      return this.web_config && this.web_config.website_config_room_types
    },
		rooms() {
      if (this.configRoomTypes.length > 0) {
        return this.$store.state.room_types.filter(room => this.configRoomTypes.some(item => room.id === item.room_type_id))
          .filter(room => {
            return this.accessible ? room : !room.accessible
          })
      } else {
        return this.$store.state.room_types.filter(room => room.active)
          .filter(room => {
            return this.accessible ? room : !room.accessible
          })
      }
		},
    showAccessibleCheckbox() {
      return this.$store.state.room_types.some(room => room.accessible)
    },
		hotel() {
			return this.$store.state.hotel !== null;
		},
    hotel_name() {
	    return this.hotel && this.$store.state.hotel.name
    },
    web_config() {
      return this.hotel && this.$store.state.web_config
    },
		property_code() {
			return this.$store.state.property_code;
		},
    borderColor() {
      return !!this.web_config && this.web_config.color_code;
    },
    borderStyle() {
      return !!this.web_config && (this.border_style.border += this.borderColor);
    },
    theme () {
      const theme = !!this.web_config && this.web_config.theme
      return theme ? `${theme.toLowerCase()}-theme` : null
    }
	},
	data: () => ({
    border_style:{
      border:"10px solid ",
    },
    accessible: false
	}),
};
