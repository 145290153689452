import isBefore from 'date-fns/is_before';
import isEqual from 'date-fns/is_equal';
import VueGtm from 'vue-gtm'
import Vue from "vue";
import {router} from "../main";
import propertyDetails from './../../property-details'

export const setPropDetails = ({state, commit}) => {
  let response = {data: propertyDetails}
  let hotel_data = response.data;
  let orderedImages = orderMyImages(response.data.images);
  let reswcover = {...response.data};
  let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  let room_types;

  commit('setLoading', true);
  commit('setPropertyCode', process.env.VUE_APP_PROPERTY_CODE);

  if (hotel_data.website_config.enable_google_analytics) {
    Vue.use(VueGtm, {
      id: hotel_data.website_config.google_analytics_ids,
      enabled: hotel_data.website_config.enable_google_analytics,
      debug: process.env.NODE_ENV === 'development',
      vueRouter: router,
    });
  }

  if (process.env.NODE_ENV === 'production') {

    response.data.room_types.forEach(room => {
      room.images.forEach(image => {
        if (image.url === null) {
          return
        }

        let imageName = image.url.split('/')
        if (checkImageType(imageName)) {
          image.url = `room_images/${room.id}/${imageName[imageName.length - 1].replace(/ /g, '')}`
        }
      })
      if (room.cover_photo !== null) {
        let coverImageName = room.cover_photo.split('/')
        if (checkImageType(coverImageName)) {
          room.cover_photo = `room_images/${room.id}/cover_photo-${room.id}/${coverImageName[coverImageName.length - 1].replace(/ /g, '')}`
        }
      }
    })

    response.data.website_feature_attractions.forEach(attraction => {
      attraction.website_feature_attraction_images.forEach(image => {
        if (image.image_url === null) {
          return
        }

        let imageName = image.image_url.split('/')
        if (checkImageType(imageName)) {
          image.image_url = `website_feature_attractions/${attraction.id}/${imageName[imageName.length - 1].replace(/ /g, '')}`
        }
      })
    })

    if (response.data.website_config.property_website_images.GALLERY_IMAGES) {
      response.data.website_config.property_website_images.GALLERY_IMAGES.forEach(image => {
        if (image.image_url === null) {
          return
        }

        let imageName = image.image_url.split('/')
        if (checkImageType(imageName)) {
          image.image_url = `gallery_images/${imageName[imageName.length - 1].replace(/ /g, '')}`
        }
      })
    }

    if (response.data.website_config.property_website_images.PROPERTY_SECONDARY) {
      response.data.website_config.property_website_images.PROPERTY_SECONDARY.forEach(image => {
        if (image.image_url === null) {
          return
        }

        let imageName = image.image_url.split('/')
        if (checkImageType(imageName)) {
          image.image_url = `property_secondary_images/${imageName[imageName.length - 1].replace(/ /g, '')}`
        }
      })
    }
    if (response.data.website_config.property_website_images.DISCOUNTS) {
      response.data.website_config.property_website_images.DISCOUNTS.forEach(image => {
        if (image.image_url === null) {
          return
        }

        let imageName = image.image_url.split('/')
        if (checkImageType(imageName)) {
          image.image_url = `discounts_secondary_images/${imageName[imageName.length - 1].replace(/ /g, '')}`
        }
      })
    }
    if (response.data.website_config.property_website_images.PACKAGES) {
      response.data.website_config.property_website_images.PACKAGES.forEach(image => {
        if (image.image_url === null) {
          return
        }

        let imageName = image.image_url.split('/')
        if (checkImageType(imageName)) {
          image.image_url = `packages_secondary_images/${imageName[imageName.length - 1].replace(/ /g, '')}`
        }
      })
    }

    response.data.package_rates.forEach(package_rate => {
      package_rate.package_rate_images.forEach(image => {
        if (image.url === null) {
          return
        }

        let imageName = image.url.split('/')
        if (checkImageType(imageName)) {
          image.url = `package_images/${package_rate.id}/${imageName[imageName.length - 1].replace(/ /g, '')}`
        }
      })
    })

    response.data.discounts.forEach(discount => {
      discount.discount_images.forEach(image => {
        if (image.url === null) {
          return
        }

        let imageName = image.url.split('/')
        if (checkImageType(imageName)) {
          image.url = `discounts_images/${discount.id}/${imageName[imageName.length - 1].replace(/ /g, '')}`
        }
      })
      if (discount.cover_photo) {
        let coverPhotoName = discount.cover_photo.split('/')
        if (checkImageType(coverPhotoName)) {
          discount.cover_photo = `discounts_images/${discount.id}/cover_image-${discount.id}/${coverPhotoName[coverPhotoName.length - 1].replace(/ /g, '')}`
        }
      }
    })

    response.data.images.forEach(prop_image => {
      if (prop_image.url === null) {
        return
      }

      let propImageUrl = prop_image.url.split('/')
      if (checkImageType(propImageUrl)) {
        prop_image.url = `property_images/${propImageUrl[propImageUrl.length - 1].replace(/ /g, '')}`
      }
    })

    response.data.amenities.forEach(amenitie => {
      if (amenitie.icon) {
        if (amenitie.icon === null) {
          return
        }

        let icon = amenitie.icon.split('/')
        if (checkImageType(icon)) {
          amenitie.icon = `amenities_images/${icon[icon.length - 1].replace(/ /g, '')}`
        }
      }
    })

    if (response.data.cover_photo) {
      let coverPhotoNameSplitted = response.data.cover_photo.split('/')
      if (checkImageType(coverPhotoNameSplitted)) {
        response.data.cover_photo = `${coverPhotoNameSplitted[coverPhotoNameSplitted.length - 1].replace(/ /g, '')}`
      }
    }

    if (response.data.website_menus !== null && response.data.website_menus.length > 0) {
      response.data.website_menus.forEach(menu => {
        menu.images.forEach(image => {
          if (image.url === null) {
            return
          }

          let imageName = image.url.split('/')
          if (checkImageType(imageName)) {
            image.url = `website_menu/${menu.id}/${imageName[imageName.length - 1].replace(/ /g, '')}`
          }
        })
        if (menu.sub_menus) {
          menu.sub_menus.forEach(submenu => {
            submenu.images(image => {
              if (image.url === null) {
                return
              }

              let imageName = image.url.split('/')
              if (checkImageType(imageName)) {
                image.url = `website_menu/${menu.id}/${submenu.id}/${imageName[imageName.length - 1].replace(/ /g, '')}`
              }
            })
          })
        }
      })
    }
  }

  response.data.images = orderedImages;
  commit('setHotel', response.data);

  reswcover.cover_photo = {url: reswcover.cover_photo};
  commit('addDetailedProperty', reswcover);

  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = response.data.business_logo;
  document.getElementsByTagName('head')[0].appendChild(link);

  commit('setMinimumDate', hotel_data.business_day.date.slice(0, 10));
  commit('setWebConfig', hotel_data.website_config);
  commit('setGalleryImages', hotel_data.website_config.property_website_images.GALLERY_IMAGES);
  commit('setFeatureAttractions', hotel_data.website_feature_attractions);
  room_types = hotel_data.room_types.slice(0).map(room_object => {
    return {...room_object, ['images']: orderMyImages(room_object.images)};
  });
  commit('setRoomTypes', room_types);
  commit('setAmenities', hotel_data.amenities);
  commit('setLoading', false);
};

//order images function
const orderMyImages = (imagesArr) => {
  imagesArr.slice(0).sort((a, b) => {
    let a_created = a.created_at.slice(0, 10);
    let b_created = b.created_at.slice(0, 10);

    if (isEqual(a_created, b_created)) {
      return 0;
    } else {
      isBefore(a_created, b_created) ? -1 : 1;
    }
  });
  return imagesArr;
}
const checkImageType = (imageArray) => {
  const types_allowed = ['jpg', 'jpeg', 'gif', 'png']
  const getExtension = imageArray[imageArray.length - 1].split('.')

  return types_allowed.find(t => t === getExtension[getExtension.length - 1])
}
