import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {ChevronDown} from 'vue-feather-icon';
import format from 'date-fns/format';
import addYears from 'date-fns/add_years';
import addDays from 'date-fns/add_days';
import isBefore from 'date-fns/is_before';
import isEqual from 'date-fns/is_equal';

export default {
  components: {
    flatPickr,
    chevron: ChevronDown.default
  },
  beforeMount() {
    let newDate = new Date().toString();
    this.min_date = format(newDate.split('GMT')[0], 'YYYY-MM-DD');
    this.in_date = this.min_date;
    this.out_date = format(addDays(format(newDate, 'YYYY-MM-DD'), 1), 'YYYY-MM-DD');
  },
  mounted() {
    this.year.min = parseInt(this.min_date.split('-')[0])
    this.year.max = this.year.min + 1

    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.screenWidth = document.documentElement.clientWidth
      })
    })
  },
  data: () => ({
    in_date: null,
    out_date: null,
    guestNumber: 1,
    min_date: null,
    screenWidth: document.documentElement.clientWidth,
    bold: true,
    year: {
      min: null,
      max: null
    },
    access_res_info: {
      from: {
        day: null,
        month: null,
        year: null
      },
      to: {
        day: null,
        month: null,
        year: null
      }
    },
  }),
  computed: {
    maxNumberOfGuests(){
      return (this.$store.state.hotel && this.$store.state.hotel.max_occupancy) ? this.$store.state.hotel.max_occupancy : 4
    },
    hotel() {
      return this.$store.state.hotel !== null;
    },
    access_status() {
      return this.$store.state.access_status;
    },
    check_in_date() {
      if (this.access_status) {
        return format(`${this.access_res_info.from.year}-${this.access_res_info.from.month}-${this.access_res_info.from.day}`, 'YYYY-MM-DD')
      } else {
        return this.in_date || format(format(new Date(), 'YYYY-MM-DD'));
      }
    },
    check_out_date() {
      if (this.access_status) {
        return format(`${this.access_res_info.to.year}-${this.access_res_info.to.month}-${this.access_res_info.to.day}`, 'YYYY-MM-DD')
      } else {
        return this.out_date || format(addDays(this.in_date, 1), 'YYYY-MM-DD');
      }
    },
    areDatesEqual() {
      return isEqual(this.check_in_date, this.check_out_date)
    },
    from_config() {
      return {
        disableMobile: true,
        dateFormat: 'Y-m-d',
        minDate: format(this.min_date, 'YYYY-MM-DD'),
        maxDate: format(addDays(format(addYears(this.min_date, 1), 'YYYY-MM-DD'), -1), 'YYYY-MM-DD'),
      };
    },
    to_config() {
      return {
        disableMobile: true,
        dateFormat: 'Y-m-d',
        minDate: format(addDays(this.in_date, 1), 'YYYY-MM-DD'),
        maxDate: format(this.max_date_for_checkout, 'YYYY-MM-DD'),
      };
    },
    min_month_allowed() {
      if (parseInt(this.access_res_info.from.year) === parseInt(this.year.min)) {
        return parseInt(this.min_date.split('-')[1])
      } else {
        return 1
      }
    },
    max_month_allowed() {
      if (parseInt(this.access_res_info.from.year) === parseInt(this.min_date.split('-')[0])) {
        return 12
      } else {
        return parseInt(this.min_date.split('-')[1])
      }
    },
    min_days_allowed() {
      if (parseInt(this.access_res_info.from.month) === parseInt(this.min_date.split('-')[1]) && parseInt(this.access_res_info.from.year) === parseInt(this.min_date.split('-')[0])) {
        return parseInt(this.min_date.split('-')[2])
      } else if (parseInt(this.access_res_info.from.month) !== parseInt(this.min_date.split('-')[1]) && parseInt(this.access_res_info.from.year) === parseInt(this.min_date.split('-')[0])) {
        return 1
      } else {
        return 1
      }
    },
    max_days_allowed() {
      let day_number;
      switch (parseInt(this.access_res_info.from.month)) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          day_number = 31;
          break;
        case 4:
        case 6:
        case 9:
        case 11:
          day_number = 30;
          break;
        case 2:
          if (((this.access_res_info.from.year % 4 == 0) && (this.access_res_info.from.year % 100 != 0)) || (this.access_res_info.from.year % 400 == 0)) {
            day_number = 29;
          } else {
            day_number = 28;
          }
      }

      if ((parseInt(this.access_res_info.from.year) === parseInt(this.min_date.split('-')[0]) + 1) && parseInt(this.access_res_info.from.month) === parseInt(this.min_date.split('-')[1])) {
        day_number = parseInt(this.min_date.split('-')[2]) - 1
      }

      return day_number
    },
    max_check_out_days() {
      let day_number;
      switch (parseInt(this.access_res_info.to.month)) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          day_number = 31;
          break;
        case 4:
        case 6:
        case 9:
        case 11:
          day_number = 30;
          break;
        case 2:
          if (((this.access_res_info.to.year % 4 == 0) && (this.access_res_info.to.year % 100 != 0)) || (this.access_res_info.to.year % 400 == 0)) {
            day_number = 29;
          } else {
            day_number = 28;
          }
      }

      return day_number
    },
    max_check_out_date() {
      return format(addDays(this.check_in_date, 28), 'YYYY-MM-DD')
    },
    formated_max_check_out_date() {
      return format(addDays(this.check_in_date, 28), 'DD-MMM-YYYY')
    },
    formated_min_date() {
      return format(this.min_date, 'DD-MMM-YYYY')
    },
    max_date_for_checkout() {
      let check_in_plus_28 = format(addDays(this.in_date, 28), 'YYYY-MM-DD')
      let min_date_plus_1_year = format(addYears(this.min_date, 1), 'YYYY-MM-DD')

      if (check_in_plus_28 < min_date_plus_1_year) {
        return check_in_plus_28
      }
      return min_date_plus_1_year
    },
    in_day() {
      return format(this.check_in_date, 'DD');
    },
    out_day() {
      return format(this.check_out_date, 'DD');
    },
    in_month() {
      return format(this.check_in_date, 'MMM')
    },
    out_month() {
      return format(this.check_out_date, 'MMM');
    },
    different_year() {
      return false;
    },
    web_config() {
      return this.hotel && this.$store.state.web_config
    },
    buttonColor() {
      return {
        'background-color': this.theme === 'express-theme' ? this.web_config.color_code : this.web_config.booking_color_id,
      }
    },
    theme() {
      const theme = !!this.web_config && this.web_config.theme

      return theme ? `${theme.toLowerCase()}-theme` : null

    },
    buttonDisabled() {
      return !!(this.check_in_date < this.min_date || this.check_out_date > this.max_date_for_checkout || this.check_out_date < this.check_in_date || this.areDatesEqual);
    },
    bussiness_day() {
      return format(this.$store.state.hotel.business_day.date, 'YYYY-MM-DD')
    }
  },
  methods: {
    goToBooking() {
      if (this.access_status && (this.check_in_date < this.min_date || this.check_out_date > this.max_date_for_checkout || this.check_out_date < this.check_in_date)) {
        this.$notify({
          title: 'Bad date inputs',
          message: 'Please type valid dates',
          type: 'Info'
        })
      } else {
        this.$emit('checkAvailability', {
          min_date: this.bussiness_day,
          from: this.check_in_date,
          to: this.check_out_date,
          adults: this.guestNumber,
        });
      }
    },
    stayDatesChanged() {
      let to = this.out_date
      let from = this.in_date

      let _isbefore = isBefore(to, from),
        _isequal = isEqual(to, from);

      if (_isbefore || _isequal || !to) {
        setTimeout(() => {
          this.out_date = format(addDays(this.in_date, 1), 'YYYY-MM-DD');
        }, 0);
      }
    },
  },
};
